import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import AndroidWebview from '@browser-logos/android-webview/android-webview_512x512.png';
import ChromeLogo from '@browser-logos/chrome/chrome_512x512.png';
import EdgeLogo from '@browser-logos/edge/edge_512x512.png';
import FirefoxLogo from '@browser-logos/firefox/firefox_512x512.png';
import OperaLogo from '@browser-logos/opera/opera_512x512.png';
import SafariLogo from '@browser-logos/safari/safari_512x512.png';
import { css } from '@css';
import { MobileView, browserName, isChrome, isEdge, isFirefox, isIOS, isMacOs, isOpera, isSafari, } from 'react-device-detect';
import { useI18n } from '../../hooks/use-i18n';
import AppStoreIcon from '../../img/app/app-store-icon.png';
import GooglePlayIcon from '../../img/app/google-play-icon.png';
import { getBrand } from '../../utils/brand/get-brand';
import { getItem, LocalStorageKeys, setItem } from '../../utils/local-storage';
import { UseAppButton } from './use-app-button';
import { styles } from './style';
export const OpenInAppPromo = ({ token }) => {
    const { t } = useI18n();
    const tokenInStorage = getItem(LocalStorageKeys.SHOW_OPEN_IN_APP);
    const [popupActive, setPopupActive] = useState(true);
    const [popupVisible, setPopupVisible] = useState(tokenInStorage === null || tokenInStorage !== token);
    const brand = getBrand();
    if (brand !== 'budbee') {
        return null;
    }
    const isApple = isMacOs || isIOS;
    let browserLogo;
    let knownBrowser = true;
    if (isChrome) {
        browserLogo = ChromeLogo;
    }
    else if (isFirefox) {
        browserLogo = FirefoxLogo;
    }
    else if (isSafari) {
        browserLogo = SafariLogo;
    }
    else if (isOpera) {
        browserLogo = OperaLogo;
    }
    else if (isEdge) {
        browserLogo = EdgeLogo;
    }
    else {
        knownBrowser = false;
        browserLogo = AndroidWebview;
    }
    const saveUserChoice = () => {
        setPopupActive(false);
        // Offset pull down animation
        setTimeout(() => setPopupVisible(false), 300);
        if (token) {
            setItem(LocalStorageKeys.SHOW_OPEN_IN_APP, token);
        }
    };
    if (!popupVisible)
        return null;
    return (_jsxs(MobileView, { children: [_jsx("div", { className: css(styles.overlay, popupActive ? styles.overlayActive : styles.overlayClosed) }), _jsxs("div", { className: css(styles.content, popupActive ? styles.contentActive : styles.contentClosed), children: [_jsxs("div", { className: css(styles.header), children: [t('openIn'), _jsx("div", { className: css(styles.subHeader) })] }), _jsxs("div", { className: css(styles.actions), children: [_jsxs("div", { className: css(styles.popupAction), children: [_jsx("div", { className: css(styles.imageWrapper), children: _jsx("img", { alt: "promo-pop-up-budbee", className: css(styles.image), src: isApple ? AppStoreIcon : GooglePlayIcon }) }), _jsx("span", { className: css(styles.actionTitle), children: "Budbee App" }), _jsx(UseAppButton, { callback: saveUserChoice, text: t('openInAppPopup.open') })] }), _jsxs("div", { className: css(styles.popupAction), children: [_jsx("div", { className: css(styles.imageWrapper), children: _jsx("img", { alt: "promo-pop-up-browser", className: css(styles.image), src: browserLogo }) }), _jsx("span", { className: css(styles.actionTitle), children: knownBrowser ? browserName : 'Browser' }), _jsx("button", { onClick: saveUserChoice, type: "button", className: css(styles.actionButton, styles.continueOnBrowserButton), children: t('continue') })] })] }), _jsx("div", { className: css(styles.footer) })] })] }));
};
