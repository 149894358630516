export var LocalStorageKeys;
(function (LocalStorageKeys) {
    LocalStorageKeys["HAS_SEEN_JOINT_LOCKER_INFO"] = "hasSeenJointLockerInfo";
    LocalStorageKeys["LANGUAGE"] = "language";
    LocalStorageKeys["SHOW_OPEN_IN_APP"] = "showOpenInApp";
})(LocalStorageKeys || (LocalStorageKeys = {}));
export function getItem(key) {
    const val = localStorage.getItem(key);
    if (val === undefined) {
        return null;
    }
    return val;
}
export function setItem(key, value) {
    localStorage.setItem(key, value);
}
export function removeItem(key) {
    localStorage.removeItem(key);
}
export function getHasSeenJointLockarInfoTokens() {
    const tokens = getItem(LocalStorageKeys.HAS_SEEN_JOINT_LOCKER_INFO);
    if (!tokens) {
        return null;
    }
    return JSON.parse(tokens);
}
export function removeExpiredHasSeenJointLockarInfoTokens() {
    const tokens = getHasSeenJointLockarInfoTokens();
    if (!tokens) {
        return;
    }
    const expires = new Date();
    expires.setMonth(expires.getMonth() - 1);
    Object.entries(tokens).forEach(([token, timestamp]) => {
        if (timestamp <= expires.getTime()) {
            delete tokens[token];
        }
    });
    setItem(LocalStorageKeys.HAS_SEEN_JOINT_LOCKER_INFO, JSON.stringify(tokens));
}
export function setHasSeenJointLockerInfo(token) {
    if (!token) {
        return;
    }
    const tokens = getHasSeenJointLockarInfoTokens() || {};
    if (!tokens[token]) {
        tokens[token] = Date.now();
        setItem(LocalStorageKeys.HAS_SEEN_JOINT_LOCKER_INFO, JSON.stringify(tokens));
    }
}
export function hasSeenJointLockerInfo(token) {
    const tokens = getHasSeenJointLockarInfoTokens();
    return !!token && !!tokens && !!tokens[token];
}
