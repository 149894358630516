import { languages, locale } from '@budbee/js/tracking';
import moment from 'moment-timezone';
import 'moment/locale/da';
import 'moment/locale/en-gb';
import 'moment/locale/fi';
import 'moment/locale/nl';
import 'moment/locale/sv';
import { getItem, LocalStorageKeys, removeItem, setItem } from './local-storage';
function isFinnishVowel(char) {
    return ['a', 'e', 'i', 'o', 'u', 'y', 'ä', 'ö'].indexOf(char.toLowerCase()) !== -1;
}
function lastChar(str) {
    return str.substring(str.length - 1);
}
function shouldAddSuffix(language) {
    return language === languages.FINNISH;
}
function addFinnishSuffixforVowel(merchantName) {
    if (lastChar(merchantName) === lastChar(merchantName).toUpperCase()) {
        return `${merchantName}N`;
    }
    return `${merchantName}n`;
}
function addFinnishSuffixForConsonant(merchantName) {
    if (lastChar(merchantName) === lastChar(merchantName).toUpperCase()) {
        return `${merchantName}IN`;
    }
    return `${merchantName}in`;
}
export function localizeMerchantName(merchantName, language) {
    if (!shouldAddSuffix(language)) {
        return merchantName;
    }
    switch (language) {
        case languages.FINNISH:
            if (isFinnishVowel(lastChar(merchantName))) {
                return addFinnishSuffixforVowel(merchantName);
            }
            return addFinnishSuffixForConsonant(merchantName);
        default:
            return merchantName;
    }
}
export function isLanguageSupported(language) {
    return Object.values(languages).includes(language);
}
export function localizeFloorNumber(floor, t) {
    if (floor !== 0) {
        return floor;
    }
    return t('accessSettings.groundFloorAbbr');
}
export function setMomentLocale(language) {
    locale.setMomentLocale(language === languages.ENGLISH ? 'en-gb' : language);
    moment.locale(language === languages.ENGLISH ? 'en-gb' : language);
}
export function getPersistedPreferredLanguage() {
    const language = getItem(LocalStorageKeys.LANGUAGE);
    if (language && language === languages.ENGLISH) {
        return language;
    }
    return null;
}
export function persistPreferredLanguage(language) {
    if (language === languages.ENGLISH) {
        setItem(LocalStorageKeys.LANGUAGE, language);
    }
    else {
        removeItem(LocalStorageKeys.LANGUAGE);
    }
}
